exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-develop-js": () => import("./../../../src/pages/develop.js" /* webpackChunkName: "component---src-pages-develop-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-economy-js": () => import("./../../../src/pages/economy.js" /* webpackChunkName: "component---src-pages-economy-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-gunz-community-js": () => import("./../../../src/pages/gunz-community.js" /* webpackChunkName: "component---src-pages-gunz-community-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-off-the-grid-js": () => import("./../../../src/pages/off-the-grid.js" /* webpackChunkName: "component---src-pages-off-the-grid-js" */),
  "component---src-pages-press-center-js": () => import("./../../../src/pages/press-center.js" /* webpackChunkName: "component---src-pages-press-center-js" */),
  "component---src-pages-press-center-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/press-center/{markdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-press-center-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-technocore-js": () => import("./../../../src/pages/technocore.js" /* webpackChunkName: "component---src-pages-technocore-js" */)
}

